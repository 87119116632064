<template>
  <div
    class="userservice"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 表格 -->
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          prop="id"
          label="规则编号"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="equipmentType"
          label="设备分类"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="subject"
          label="播报主题"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="desc"
          label="触发条件说明"
          width="400"
        ></el-table-column>
        <el-table-column prop="date" label="推送时间" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.date == null">即时发送</div>
            <div v-else style="cursor: pointer">
              {{ "当天" + scope.row.date }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="interval" label="推送间隔" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.interval == null">不可用</div>
            <div v-else style="cursor: pointer">
              {{ scope.row.interval }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="paper" label="呼叫器提醒" width="100">
          <template slot-scope="scope">
            <el-button
              size="small"
              :disabled="true"
              style="cursor: pointer"
              v-if="scope.row.paper == 1"
              @click="ReminderClick(scope.row)"
            >
              <span>开启</span>
            </el-button>

            <el-button
              size="small"
              :disabled="true"
              style="cursor: pointer"
              v-else
              @click="ReminderClick(scope.row)"
            >
              <span>关闭</span>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="shortMessage" label="短信提醒" width="100">
          <template slot-scope="scope">
            <el-button
              size="small"
              style="cursor: pointer"
              v-if="scope.row.shortMessage == 1"
              @click="smsClick(scope.row)"
            >
              <span>开启</span>
            </el-button>

            <el-button
              size="small"
              style="cursor: pointer"
              v-else
              @click="smsClick(scope.row)"
            >
              <span>关闭</span>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="app" label="App提醒" width="100">
          <template slot-scope="scope">
            <el-button
              size="small"
              :disabled="true"
              style="cursor: pointer"
              v-if="scope.row.app == 1"
              @click="appClick(scope.row)"
            >
              <span>开启</span>
            </el-button>

            <el-button
              size="small"
              :disabled="true"
              style="cursor: pointer"
              v-else
              @click="appClick(scope.row)"
            >
              <span>关闭</span>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="configuration" label="语音配置" width="100">
          <template slot-scope="scope">
            <el-button v-if="scope.row.paper == 1" @click="configure(scope.row)"
              >配置</el-button
            >
            <div v-if="scope.row.paper == 0">无</div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="总开关" width="100">
          <template slot-scope="scope">
            <el-button
              size="small"
              style="cursor: pointer"
              v-if="scope.row.status == 1"
              @click="AllClick(scope.row)"
            >
              <span>开启</span>
            </el-button>

            <el-button
              size="small"
              style="cursor: pointer"
              v-else
              @click="AllClick(scope.row)"
            >
              <span>关闭</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 总结 -->
    <div class="total">
      <!-- 分页 -->
      <div class="right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :current-page="pageCount"
          layout="total, prev, pager, next"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 推送时间弹框 -->
    <el-dialog :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="发送时间:" :label-width="formLabelWidth">
          <el-select v-model="form.region" placeholder="请选择活动区域">
            <el-option
              v-for="item in regionType"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
          <el-time-select
            v-model="form.time"
            v-show="form.region == 1"
            style="margin-left: 30px; width: 160px"
            :picker-options="{
              start: '08:30',
              step: '00:15',
              end: '18:30',
            }"
            placeholder="选择时间"
          >
          </el-time-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 语音配置弹框 -->
    <el-dialog :visible.sync="dialogTableVisible">
      <el-form :model="configuren">
        <el-form-item :label-width="formLabelWidths">
          <div style="display: flex">
            <el-select
              v-model="configuren.cDefault"
              placeholder="请选择"
              style="width: 25%; flex-wrap: wrap; align-items: start"
            >
              <el-option
                v-for="item in defaults"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <el-upload
              ref="upload"
              accept=".amr"
              action="#"
              :auto-upload="false"
              :multiple="false"
              :file-list="fileList"
              :before-upload="beforeUpload"
              :http-request="uploadHttpRequest"
              :on-remove="fileRemove"
              :on-change="
                (file, fileList) => {
                  fileChange(file, fileList, configuren);
                }
              "
              :headers="myHeaders"
              style="width: 35%"
            >
              <input
                ref="file"
                v-model="configuren.textName"
                size="small"
                style="
                  width: 100%;
                  height: 38px;
                  margin-left: 15px;
                  border: 1px solid #dcdfe6;
                  padding: 0 15px;
                "
              />
            </el-upload>
            <el-button
              @click="preserves(configuren)"
              style="height: 38px; margin-left: 10px"
              >保存</el-button
            >
          </div>
        </el-form-item>
        <el-form-item
          v-for="(item, index) in configures.dynamicItem"
          :key="index"
          :label-width="formLabelWidths"
        >
          <div style="display: flex">
            <el-select
              v-model="item.groupId"
              placeholder="请选择"
              style="width: 25%; flex-wrap: wrap; align-items: start"
            >
              <el-option
                v-for="items in defaultTwo"
                :key="items.id"
                :value="items.id"
                :label="items.name"
              ></el-option>
            </el-select>
            <el-upload
              ref="upload"
              accept=".amr"
              action="#"
              :auto-upload="false"
              :multiple="false"
              :file-list="fileListTwo"
              :before-upload="beforeUploadTwo"
              :http-request="uploadHttpRequestTwo"
              :on-remove="fileRemoveTwo"
              :on-change="
                (file, fileList) => {
                  fileChangeTwo(file, fileList, item);
                }
              "
              :headers="myHeaders"
              style="width: 35%"
            >
              <input
                v-model="item.url"
                size="small"
                style="
                  width: 100%;
                  height: 38px;
                  margin-left: 15px;
                  border: 1px solid #dcdfe6;
                  padding: 0 15px;
                "
              />
            </el-upload>
            <el-button
              style="height: 38px; margin-left: 10px"
              @click="preserveItem(item, index)"
              >保存</el-button
            >
            <el-button
              style="height: 38px; margin-left: 10px"
              @click="deleteItem(item, index)"
              >删除</el-button
            >
          </div>
        </el-form-item>
        <el-form-item :label-width="formLabelWidths">
          <el-button
            @click="addList()"
            style="height: 38px"
            :disabled="
              this.configuren.textName !== '' && this.addTF == 1 ? false : true
            "
            >增加+</el-button
          >
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()" style="height: 38px">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { api } from "../../api1/config";
import { apiShout } from "../../api1/configShout";
import * as qiniu from "qiniu-js";
export default {
  inject: ["reload"],
  data() {
    return {
      clientHeight: document.body.clientHeight,
      // 分页
      pageSize: 20,
      totalCount: 0,
      pageCount: 1,
      //上传音频文件
      fileList: [],
      fileListTwo: [],
      //   表格
      tableData: [],
      dialogFormVisible: false,
      //推送时间
      form: {
        region: "1",
        time: "08:30",
      },
      formLabelWidth: "80px",
      formLabelWidths: "30px",
      //发送时间选择器
      regionType: [
        {
          id: "0",
          name: "即时发送",
        },
        {
          id: "1",
          name: "选择时间",
        },
      ],
      configuren: {
        cDefault: 0,
        textName: "",
        id: null,
      },
      //配置
      configures: {
        dynamicItem: [],
      },
      //配置默认值
      defaults: [
        {
          id: 0,
          name: "默认提示",
        },
      ],
      //配置默人默认值
      defaultTwo: [],
      myHeaders: { "Content-Type": "multipart/form-data" },
      dialogTableVisible: false,
      ids: null,
      idsn: null,
      rowIDs: null,
      ConfItem: [],
      addTF: 0,
      rowList: {},
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //呼叫器提醒开启关闭
    ReminderClick(num) {
      console.log(num, "查看值");
      console.log(num.paper, "paper");
      this.$confirm("是否修改呼叫器提醒开关, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (num.paper == 1) {
            num.paper = 0;
          } else {
            num.paper = 1;
          }
          let obj = {
            id: num.id,
            paper: num.paper,
          };
          apiShout.EquipmentAiBroadcast(obj).then((res) => {
            console.log(num.paper, "弹框查看改变后的值");
            if (res.data.code === 200) {
              this.$message({
                type: "success",
                message: "修改成功!",
              });
              this.getList();
            } else {
              if (num.paper == 0) {
                num.paper = 1;
              } else {
                num.paper = 0;
              }
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //短信提醒开启关闭
    smsClick(num) {
      console.log(num, "查看值");
      console.log(num.shortMessage, "查看改变后的值");
      this.$confirm("是否修改短信提醒开关, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (num.shortMessage == 1) {
            num.shortMessage = 0;
          } else {
            num.shortMessage = 1;
          }
          let obj = {
            id: num.id,
            shortMessage: num.shortMessage,
          };
          apiShout.EquipmentAiBroadcast(obj).then((res) => {
            console.log(num.shortMessage, "弹框查看改变后的值");
            if (res.data.code === 200) {
              this.$message({
                type: "success",
                message: "修改成功!",
              });
              this.getList();
            } else {
              if (num.shortMessage == 0) {
                num.shortMessage = 1;
              } else {
                num.shortMessage = 0;
              }
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //App提醒开启关闭
    appClick(num) {
      console.log(num, "查看值");
      console.log(num.app, "查看改变后的值");
      this.$confirm("是否修改App提醒开关, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (num.app == 1) {
            num.app = 0;
          } else {
            num.app = 1;
          }
          let obj = {
            id: num.id,
            app: num.app,
          };
          apiShout.EquipmentAiBroadcast(obj).then((res) => {
            console.log(num.app, "弹框查看改变后的值");
            if (res.data.code === 200) {
              this.$message({
                type: "success",
                message: "修改成功!",
              });
              this.getList();
            } else {
              if (num.app == 0) {
                num.app = 1;
              } else {
                num.app = 0;
              }
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //总开关
    AllClick(num) {
      console.log(num, "查看值");
      console.log(num.app, "查看改变后的值");
      this.$confirm("是否修改总开关, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (num.status == 1) {
            num.status = 0;
          } else {
            num.status = 1;
          }
          if (num.id == 5 || num.id == 6 || num.id == 14) {
            apiShout
              .EquipmentAiBroadcast({ id: 5, status: num.status })
              .then((res) => {
                console.log(num.status, "弹框查看改变后的值");
                if (res.data.code === 200) {
                  // this.$message({
                  //   type: "success",
                  //   message: "修改成功!",
                  // });
                  this.getList();
                } else {
                  if (num.status == 0) {
                    num.status = 1;
                  } else {
                    num.status = 0;
                  }
                  // this.$message.error(res.data.msg);
                }
              });
            apiShout
              .EquipmentAiBroadcast({ id: 6, status: num.status })
              .then((res) => {
                console.log(num.status, "弹框查看改变后的值");
                if (res.data.code === 200) {
                  // this.$message({
                  //   type: "success",
                  //   message: "修改成功!",
                  // });
                  this.getList();
                } else {
                  if (num.status == 0) {
                    num.status = 1;
                  } else {
                    num.status = 0;
                  }
                  // this.$message.error(res.data.msg);
                }
              });
            apiShout
              .EquipmentAiBroadcast({ id: 14, status: num.status })
              .then((res) => {
                console.log(num.status, "弹框查看改变后的值");
                if (res.data.code === 200) {
                  this.$message({
                    type: "success",
                    message: "修改成功!",
                  });
                  this.getList();
                } else {
                  if (num.status == 0) {
                    num.status = 1;
                  } else {
                    num.status = 0;
                  }
                  this.$message.error(res.data.msg);
                }
              });
          } else {
            let obj = {
              id: num.id,
              status: num.status,
            };
            apiShout.EquipmentAiBroadcast(obj).then((res) => {
              console.log(num.status, "弹框查看改变后的值");
              if (res.data.code === 200) {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                this.getList();
              } else {
                if (num.status == 0) {
                  num.status = 1;
                } else {
                  num.status = 0;
                }
                this.$message.error(res.data.msg);
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //获取列表数据
    getList() {
      console.log("进入查询列表数据接口");
      apiShout
        .getAiBroadcast({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
        })
        .then((res) => {
          // console.log("400中心：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
          }
        });
    },
    //配置
    configure(row) {
      this.configures.dynamicItem = [];
      console.log(row, "点击配置查询到的数据");
      this.rowList = row;
      this.rowIDs = row.id;
      this.dialogTableVisible = true;
      apiShout.getGroups().then((res) => {
        console.log("销售分组：", res.data);
        if (res.data.code == 200) {
          this.defaultTwo = res.data.result;
        }
      });
      apiShout.getAiBroadcastConf({ castId: row.id }).then((res) => {
        if (res.data.code === 200) {
          console.log(res.data, "res配置");
          if (res.data.result.length !== 0) {
            this.addTF = 1;
          } else {
            this.configuren.textName = "";
            this.configuren.id = undefined;
          }
          this.ConfItem.push(res.data.result);
          res.data.result.forEach((element) => {
            if (element.groupId === 0) {
              this.configuren.cDefault = element.groupId;
              this.configuren.textName = element.url;
              this.configuren.id = element.id;
              //   this.configuren.id = element.id;
              console.log(this.configuren.id, "zouleid");
            } else {
              let objList = {};
              objList.groupId = element.groupId;
              objList.url = element.url;
              objList.id = element.id;
              objList.broadcastId = element.broadcastId;
              this.configures.dynamicItem.push(objList);
              //   this.configuren.id = element.id;
              console.log("zouleididi");
              console.log(this.configures.dynamicItem, "数据接push收到的");
              //   this.configures.dynamicItem = element;
              //   console.log(this.configures.dynamicItem,'elemenetsssss')
              //   let array = [];
              //   let arrays = {};
              //   this.configures.dynamicItem.forEach(elements =>{
              //       elements = element;
              //       console.log(elements,'elementss')
              //       arrays = elements
              //       console.log(arrays,'arrays')
              //   })
              //   array.push(arrays)
              //   console.log(array,'arraypush')
            }
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
      api.getQiuNiu({ type: "broadcast" }).then((res) => {
        console.log("获取七牛云存储配置：", res.data.result);
        this.token = res.data.result.token;
        this.accessKey = res.data.result.accessKey;
        this.bucket = res.data.result.bucket;
        this.secretKey = res.data.result.secretKey;
        this.prefix = res.data.result.prefix;
      });
    },
    //隐藏事件推送时间
    pushTimes(time) {
      console.log(time, "推送时间");
      this.dialogFormVisible = true;
      this.form.region = "1";
      this.form.time = "08:30";
    },
    //上传音频
    beforeUpload(file) {
      console.log(file, "file查询");
      this.files = file;
      this.fileName = file.name;
      // 文件大小限制为10M
      const fileLimit = file.size / 1024 / 1024 < 1;
      if (!fileLimit) {
        this.$message.error("上传文件大小不超过1M！");
      }
      return fileLimit;
    },
    // 移除选择的文件
    fileRemove(file, fileList) {
      console.log(file, "file查询2");
      if (fileList.length < 1) {
        this.uploadDisabled = true; //未选择文件则禁用上传按钮
      }
    },
    //批量发布
    uploadHttpRequest(param) {
      console.log("file查询3");
      console.log("aaaa:", param);
      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      formData.append("fileName", param.file); //添加文件对象
      // formData.append("fileName", param.file.name);
      console.log("565656:", formData.getAll("file"));
      // const url = '/too/center/equipment/batchImportEquipment'; //上传地址
    },

    // 文件发生改变
    fileChange(file, fileList, item) {
      console.log(file, "file查询4");
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //清空图片
      this.fileList = [];
      // console.log("===========", this.$refs.upload.uploadFiles[0]);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      this.ids = this.userinfo.id;
      console.log(file, "files");
      console.log(file, "filessssss");
      if (file.length === 0) {
        alert("请选择文件");
        return;
      }
      let files = file.raw;
      let shijiancuo = new Date().getTime();
      let token = this.token;
      //处理文件拓展名
      let indexName = files.name.lastIndexOf(".");
      console.log(indexName, "indexNamesss");
      let ming = files.name.slice(indexName);
      console.log(ming, "mingsss");
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        files,
        this.prefix + this.ids + shijiancuo + ming,
        token,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          loading.close();
          item.textName = "http://cdn.careld.cn/" + res.key;
        },
      };
      observable.subscribe(observer); // 上传开始
      //   this.configuren.textName = file.name;
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; // 展示最后一次选择的文件
        fileList.push(file);
      }
    },
    //上传音频2
    beforeUploadTwo(file) {
      console.log(file, "file查询");
      this.files = file;
      this.fileName = file.name;
      // 文件大小限制为10M
      const fileLimit = file.size / 1024 / 1024 < 1;
      if (!fileLimit) {
        this.$message.error("上传文件大小不超过1M！");
      }
      return fileLimit;
    },
    // 移除选择的文件2
    fileRemoveTwo(file, fileList) {
      console.log(file, "file查询2");
      if (fileList.length < 1) {
        this.uploadDisabled = true; //未选择文件则禁用上传按钮
      }
    },
    //批量发布2
    uploadHttpRequestTwo(param) {
      console.log("file查询3");
      console.log("aaaa:", param);
      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      formData.append("fileName", param.file); //添加文件对象
      // formData.append("fileName", param.file.name);
      console.log("565656:", formData.getAll("file"));
      // const url = '/too/center/equipment/batchImportEquipment'; //上传地址
    },
    // 文件发生改变2
    fileChangeTwo(file, fileList, item) {
      console.log(file, fileList, item, "file查询4");
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      this.fileList = [];
      // console.log("===========", this.$refs.upload.uploadFiles[0]);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      this.idsn = this.userinfo.id;
      console.log(file, "files");
      console.log(file, "filessssss");
      if (file.length === 0) {
        alert("请选择文件");
        return;
      }
      let files = file.raw;
      let shijiancuo = new Date().getTime();
      let token = this.token;
      //处理文件拓展名
      let indexName = files.name.lastIndexOf(".");
      console.log(indexName, "indexNamesss");
      let ming = files.name.slice(indexName);
      console.log(ming, "mingsss");
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        files,
        this.prefix + this.idsn + shijiancuo + ming,
        token,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          loading.close();
          item.url = "http://cdn.careld.cn/" + res.key;
        },
      };
      observable.subscribe(observer); // 上传开始
      //   item.url = file.name;
      if (fileList.length > 0) {
        this.fileListTwo = [fileList[fileList.length - 1]]; // 展示最后一次选择的文件
        fileList.push(file);
      }
    },
    //新增表单
    addList() {
      this.configures.dynamicItem.push({
        groupId: "",
        url: "",
      });
    },
    //删除表单
    deleteItem(item, index) {
      console.log(item, index, "删除输出查询");
      this.$confirm("是否删除此条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (item.id !== undefined) {
            apiShout.deleteAiBroadcastConf({ id: item.id }).then((res) => {
              if (res.data.code === 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.configures.dynamicItem.splice(index, 1);
                this.configure(this.rowList);
              } else {
                this.$message.error(res.data.msg);
              }
            });
          } else {
            this.configures.dynamicItem.splice(index, 1);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //默认首行保存表单
    preserves(item) {
      console.log(item, "保存输出查询");
      this.$confirm("是否保存此条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(item.id, "item.idid");
          if (item.id !== undefined) {
            console.log("修改");
            apiShout
              .updateAiBroadcastConf({
                id: item.id,
                groupId: item.cDefault,
                url: item.textName,
              })
              .then((res) => {
                if (res.data.code === 200) {
                  console.log(res.data, "修改表单");
                  this.$message({
                    type: "success",
                    message: "修改成功!",
                  });
                  this.configure(this.rowList);
                  this.addTF = 1;
                } else {
                  this.$message.error(res.data.msg);
                }
              });
          } else {
            console.log("新增");
            apiShout
              .insertAiBroadcastConf({
                broadcastId: this.rowIDs,
                groupId: item.cDefault,
                url: item.textName,
              })
              .then((res) => {
                if (res.data.code === 200) {
                  console.log(res.data, "新增表单");
                  this.$message({
                    type: "success",
                    message: "新增成功!",
                  });
                  this.configure(this.rowList);
                  this.addTF = 1;
                } else {
                  this.$message.error(res.data.msg);
                }
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //保存表单
    preserveItem(item, index) {
      console.log(item, index, "保存输出查询");
      this.$confirm("是否保存此条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(item.id, "查询");
          if (item.id !== undefined) {
            console.log("修改");
            apiShout
              .updateAiBroadcastConf({
                id: item.id,
                groupId: item.groupId,
                url: item.url,
              })
              .then((res) => {
                if (res.data.code === 200) {
                  console.log(res.data, "修改表单");
                  this.$message({
                    type: "success",
                    message: "修改成功!",
                  });
                  this.addTF = 1;
                  this.configure(this.rowList);
                } else {
                  this.$message.error(res.data.msg);
                }
              });
          } else {
            console.log("新增");
            apiShout
              .insertAiBroadcastConf({
                broadcastId: this.rowIDs,
                groupId: item.groupId,
                url: item.url,
              })
              .then((res) => {
                if (res.data.code === 200) {
                  console.log(res.data, "新增表单");
                  this.$message({
                    type: "success",
                    message: "新增成功!",
                  });
                  this.addTF = 1;
                  this.configure(this.rowList);
                } else {
                  this.$message.error(res.data.msg);
                }
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //取消弹框刷
    cancel() {
      this.dialogTableVisible = false;
      //   this.reload();
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageCount = val;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-upload-list {
  display: none;
}
/deep/.el-upload__input {
  display: none;
}
.userservice {
  .search {
    height: 80px;
    margin: 0 40px;
    margin-left: 25px;
    display: flex;
    align-items: center;
    .span1 {
      display: block;
      font-size: 14px;
      width: auto;
      color: #909399;
      margin-right: 1%;
      font-weight: bold;
    }
    .el-select {
      margin-right: 60px;
      margin-left: 10px;
    }
    .el-input {
      width: 120px;
      margin-left: 10px;
    }
    .in1 {
      margin-right: 10px;
    }
    .in2 {
      margin-right: 60px;
    }
  }
  // table
  .tables {
    margin-left: 25px;
    margin-right: 40px;
    .el-table::before {
      height: 0;
    }
    .el-table {
      td,
      th.is-leaf {
        border: none !important;
      }
      tr th {
        font-size: 19px;
        color: #a8a8a8;
        text-align: center;
      }
      tr td {
        text-align: center;
        font-size: 17px;
        color: #a8a8a8;
      }
    }
  }
  /deep/ .el-table .el-table__cell {
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  /deep/ th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  // 弹框
  .el-dialog {
    border-radius: 18px;
  }
  /deep/.el-dialog__header {
    border-radius: 18px 18px 0 0;
    padding: 30px 20px 10px;
  }
  .el-dialog__close {
    color: transparent;
    background-image: url("../../assets/images/close.png");
    background-repeat: no-repeat;
    background-size: 14px;
  }
  .el-dialog__body {
    border-radius: 0 0 18px 18px;
    padding: 10px 20px 0 20px;
  }
  // 总结
  .total {
    display: flex;
    justify-content: flex-end;
    margin-left: 85px;
    margin-top: 20px;
    .right {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .el-pagination {
        padding: 0;
        .el-pager,
        .el-pager li {
          color: #afafaf;
          font-size: 17px;
          font-weight: normal;
        }
        .el-pager li.active {
          color: #d78673;
        }
        .el-pager li:hover {
          color: #d78673;
        }
        img {
          display: inline-block;
        }
        .lefts {
          margin-right: 25px;
        }
        .rights {
          float: right;
          margin-left: 25px;
        }
      }
    }
    p {
      font-size: 15px;
      display: inline-block;
      margin-right: 80px;
      color: #606266;
    }
  }
  .el-button {
    padding: 6px 20px;
    font-size: 13px;
  }
  // 按钮
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    .el-button {
      width: 130px;
      height: 40px;
      line-height: 40px;
      border-radius: 25px;
      margin-top: 20px;
      margin-right: 20px;
      background: #d78673;
      color: #ffffff;
      font-size: 16px;
      padding: 0px;
    }
  }
  /deep/.el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.el-table .cell {
    text-align: center !important;
  }
  /deep/.el-table thead tr th.is-leaf {
    border: none;
    border-right: none;
  }
}
</style>